import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { BhAnalyticsRouteGuardService } from './services/_core/bh-analytics-route-guard/bh-analytics-route-guard.service';
import { LoginRouteGuardService } from './services/_core/login-route-guard/login-route-guard.service';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'login',
    pathMatch: 'full',
    canActivate: [BhAnalyticsRouteGuardService],
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'help-forgot-pwd',
    canActivate: [BhAnalyticsRouteGuardService],
    loadChildren: () =>
      import('./pages/_core/help-forgot-pwd/help-forgot-pwd.module').then(m => m.HelpForgotPwdPageModule)
  },
  {
    path: 'share',
    canActivate: [BhAnalyticsRouteGuardService],
    loadChildren: () => import('./pages/_core/share/share.module').then(m => m.SharePageModule)
  },
  {
    path: 'help',
    canActivate: [BhAnalyticsRouteGuardService],
    loadChildren: () => import('./pages/_core/help/help.module').then(m => m.HelpPageModule)
  },
  {
    path: 'help-no-pin',
    canActivate: [BhAnalyticsRouteGuardService],
    loadChildren: () => import('./pages/_core/help-no-pin/help-no-pin.module').then(m => m.HelpNoPinPageModule)
  },
  {
    path: 'errors',
    canActivate: [BhAnalyticsRouteGuardService],
    loadChildren: () => import('./pages/_core/errors/errors.module').then(m => m.ErrorsPageModule)
  },
  {
    path: 'errors-detail',
    canActivate: [BhAnalyticsRouteGuardService],
    loadChildren: () => import('./pages/_core/errors-detail/errors-detail.module').then(m => m.ErrorsDetailPageModule)
  },
  {
    path: 'check-for-updates',
    canActivate: [BhAnalyticsRouteGuardService],
    data: { pageName: 'Check for Updates', roles: [] },
    loadChildren: () => import('./pages/_core/check-for-updates/check-for-updates.module').then(m => m.CheckForUpdatesPageModule)
  },
  {
    path: 'about',
    canActivate: [LoginRouteGuardService, BhAnalyticsRouteGuardService],
    loadChildren: () => import('./pages/_core/about/about.module').then(m => m.AboutPageModule)
  },
  {
    path: 'my-account',
    canActivate: [LoginRouteGuardService, BhAnalyticsRouteGuardService],
    data: { pageName: 'My Account', roles: ['USER', 'ADMIN', 'SYS_ADMIN'] },
    loadChildren: () => import('./pages/_core/my-account/my-account.module').then(m => m.MyAccountPageModule)
  },
  {
    path: 'bh-input-text-editor',
    loadChildren: () => import('./components/_core/bh-input-text-editor/bh-input-text-editor.module')
      .then(m => m.BhInputTextEditorPageModule)
  },
  {
    path: 'feedback',
    data: { pageName: 'Feedback', roles: [] },
    loadChildren: () => import('./pages/_core/feedback/feedback.module').then(m => m.FeedbackPageModule)
  },
  {
    path: 'notifications',
    data: { pageName: 'Notifications', roles: ['USER', 'ADMIN', 'SYS_ADMIN'] },
    loadChildren: () => import('./pages/_core/notifications/notifications.module').then( m => m.NotificationsPageModule)
  },
  {
    path: 'select-vaccine',
    canActivate: [LoginRouteGuardService, BhAnalyticsRouteGuardService],
    data: { pageName: 'Select vaccine', roles: [] },
    loadChildren: () => import('./pages/select-vaccine/select-vaccine.module').then( m => m.SelectVaccinePageModule)
  },
  {
    path: 'employee-verify',
    loadChildren: () => import('./pages/employee-verify/employee-verify.module').then( m => m.EmployeeVerifyPageModule)
  },
  {
    path: 'employee-administer',
    loadChildren: () => import('./pages/employee-administer/employee-administer.module').then( m => m.EmployeeAdministerPageModule)
  },
  {
    path: 'manage-tabs',
    loadChildren: () => import('./pages/manage-tabs/manage-tabs.module').then( m => m.ManageTabsPageModule)
  },
  {
    path: 'manage-dashboard',
    loadChildren: () => import('./pages/manage-dashboard/manage-dashboard.module').then( m => m.ManageDashboardPageModule)
  },
  {
    path: 'manage-users',
    loadChildren: () => import('./pages/manage-users/manage-users.module').then( m => m.ManageUsersPageModule)
  },
  {
    path: 'manage-users-edit',
    loadChildren: () => import('./pages/manage-users-edit/manage-users-edit.module').then( m => m.ManageUsersEditPageModule)
  },
  {
    path: 'manage-vaccine',
    loadChildren: () => import('./pages/manage-vaccine/manage-vaccine.module').then( m => m.ManageVaccinePageModule)
  },
  {
    path: 'manage-vaccine-edit',
    loadChildren: () => import('./pages/manage-vaccine-edit/manage-vaccine-edit.module').then( m => m.ManageVaccineEditPageModule)
  },
  {
    path: 'manage-vaccine-loc-edit',
    loadChildren: () => import('./pages/manage-vaccine-loc-edit/manage-vaccine-loc-edit.module').then( m => m.ManageVaccineLocEditPageModule)
  },
  {
    path: 'manage-vaccine-lot-edit',
    loadChildren: () => import('./pages/manage-vaccine-lot-edit/manage-vaccine-lot-edit.module').then( m => m.ManageVaccineLotEditPageModule)
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
