import { User } from 'src/app/models/user';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  env = environment;

  constructor(
    private http: HttpClient,

  ) { }

  getAll(activeOnly: boolean): Observable<User[]> {
    const url = `${this.env.apiUrl}/getUsers`;
    console.log('getUsers');
    const body = {
      field: ''
    };
    return this.http.post(url, body).pipe(
      map((data: any) => {
        return data.users;
      })
    );
  }

  getById(userId): Observable<any> {
    const url = `${this.env.apiUrl}/getUsers`;
    const body = {
      en: userId
    };
    return this.http.post(url, body).pipe(
      map((data: any) => {
        console.log('getbyid', data);
        if (data.users) {
          return data.users[0];
        } else {
          return null;
        }
      })
    );
  }

  create(user: User): Observable<any> {
    const url = `${this.env.apiUrl}/saveUser`;
    return this.http.post(url, user).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  update(user: User): Observable<any> {
    const url = `${this.env.apiUrl}/saveUser`;
    return this.http.post(url, user).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  delete(user: User): Observable<any> {
    const url = `${this.env.apiUrl}/deleteUser`;
    return this.http.post(url, user).pipe(
      map((data: any) => {
        return data;
      })
    );
  }
}
